import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic04 from '../assets/images/pic04.jpg'

class AboutUs extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="About Us" />
        <HeaderGeneric />
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>
            <h2>About Us</h2>
            <p>Mastered Software specializes in developing scalable, high-performance web and mobile applications and games. Contact us for all your custom software solutions.</p>
            <p>Mastered Software provides custom Software solutions to match your needs. We specialize in creating scalable enterprise-grade websites and web applications using NodeJS, Typescript, JavaScript and React. Our developers are experienced in other modern programming languages and technology stacks including: Ruby, Python, C#, Java, PHP, and C/C++. We are well versed in relational databases such as Microsoft SQL Server, Oracle, MySQL and PostgreSQL as well as NoSQL systems such as MongoDB, Redis, and Hadoop/Hive.</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default AboutUs
